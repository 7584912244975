import React from 'react'

import style from './about.module.css'
import Message from './message'
import Support from './support'
import Benifitial from './benifitial'

const AboutTemplate = () => {
	return(
		<section className={style.aboutSection}>
			<Message style={style}/>
			<Support style={style}/>
			<div className={style.aboutShowCase}/>
			<Benifitial style={style}/>
		</section>
	)
}

export default AboutTemplate