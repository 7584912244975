import React from 'react';
import About from './about-template'

const AboutIndex = () => {
	return (
		<>
			<About/>
		</>
	)
}

export default AboutIndex