import React from 'react'
import {graphql, useStaticQuery} from "gatsby"

import AboutImg from '../../../images/about/about.png';

const M = ({style}) =>{
	const query = useStaticQuery(graphql`
		query {
			allStrapiPages(filter: {slug: {eq: "Hi-I-m-Harmonie"}}) {
			    edges {
			      node {
			        excerpt
			        name
			        slug
			        feature_image {
			          childImageSharp {
			            fluid {
			              src
			            }
			          }
			        }
			      }
			    }
			}
		}
	`)
	let d = [];
	query.allStrapiPages.edges.forEach(({node})=>{
		d.push(node);
	})
	d = d[0]
	return (
		<div className={style.container}>
			<div className={style.aboutContentWrapperBg}>
				<h3>
					About Reviving Wholeness
				</h3>
				<div className={style.aboutContentWrapper}>
					<div className={style.aboutContent}>
						<h4>{d.name}</h4>
						<p>
							{d.excerpt}
						</p>
					</div>
				</div>
			</div>
			<div className={style.aboutImg}>
				<img src={d.feature_image.childImageSharp.fluid.src} alt=""/>
			</div>
		</div>
	)
}

export default M