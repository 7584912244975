import React from 'react'
import {graphql, useStaticQuery} from "gatsby"

const B = ({style}) => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiPages(filter: {slug: {eq: "how-can-i-benefit-from-reviving-wholeness"}}) {
			    edges {
			      node {
			        excerpt
			        name
			        slug
			        items {
			          value
			        }
			      }
			    }
			}
		}
	`)
	let d = [];
	query.allStrapiPages.edges.forEach(({node})=>{
		d.push(node);
	})
	d = d[0]
	return(
		<div className={style.aboutBenifitContainer}>
			<div className={style.benifitSummaryWrapper}>
				<div className={style.benifitSummaryBg}/>
				<div className={style.benifitSummaryWrap}>
					<h4>{d.name}</h4>
					<p>
						{d.excerpt}
					</p>
				</div>
			</div>
			<div className={style.benifitListWrapper}>
				<div className={style.benifitList}>
					<ul>
						{
							d.items.map((item, index) => {
								return(
									<li key={`b_${index}`}>
										{item.value}
									</li>
								)
							})
						}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default B