import React from 'react'
import {graphql, useStaticQuery} from "gatsby"

const S =({style})=>{
	const query = useStaticQuery(graphql`
		query {
			allStrapiPages(filter: {slug: {eq: "let-s-talk-about-how-we-can-help-you"}}) {
			    edges {
			      node {
			        excerpt
			        name
			        slug
			      }
			    }
			}
		}
	`)
	let d = [];
	query.allStrapiPages.edges.forEach(({node})=>{
		d.push(node);
	})
	d = d[0]
	return(
		<div className={style.aboutSummaryContainer}>
			<div className={style.aboutSummaryBg}/>
			<div className={style.aboutSummaryWrap}>
				<h4>{d.name}</h4>
				<p>
					{d.excerpt}
				</p>
			</div>
		</div>
	)
}

export default S